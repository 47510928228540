import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: require(`./en.json`),
  },
  es: {
    translation: require(`./es.json`),
  },
};


i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector) // TODO: usar idioma de Recoil
  .init({
    resources: resources,
    lng: window.location.href.includes("/es/") ? "es" : window.location.href.includes("/en/") ? "en" : "es",
    fallbackLng: `es`,
    interpolation: {
      escapeValue: false,
    },
  }).catch((e) => { console.log(e); });

export default i18n;
