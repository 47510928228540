import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Root from './Root';

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const Home = React.lazy(() => import(`../pages/Home`));
const Apps = React.lazy(() => import(`../pages/Apps`));
const Workflows = React.lazy(() => import(`../pages/Workflows`));
const MonthlySubscriptionWorkflows = React.lazy(() => import(`../pages/Flow/MonthlySubscription`));
const ProductEcommerceWorkflows = React.lazy(() => import(`../pages/Flow/ProductEcommerce`));
const ProductMarketplaceWorkflows = React.lazy(() => import(`../pages/Flow/ProductMarketplace`));
const ServiceMarketplaceWorkflows = React.lazy(() => import(`../pages/Flow/ServiceMarketplace`));
const ServicePaymentWorkflows = React.lazy(() => import(`../pages/Flow/ServiceEcommerce`));
const CartRecoveryWorkflows = React.lazy(() => import(`../pages/Flow/CartRecovery`));

const Privacy = React.lazy(() => import(`../pages/Privacy`));
const Cookies = React.lazy(() => import(`../pages/Cookies`));

const Valid = React.lazy(() => import(`../pages/Redirect/Valid`));
const Novalid = React.lazy(() => import(`../pages/Redirect/Novalid`));
const Redirect = React.lazy(() => import(`../pages/Redirect/POST`));
const MundoMarinoRedirect = React.lazy(() => import(`../pages/Redirect/POST/MundoMarino`));
const MundoMarinoValid = React.lazy(() => import(`../pages/Redirect/Valid/MundoMarino`));
const MundoMarinoNovalid = React.lazy(() => import(`../pages/Redirect/Novalid/MundoMarino`));


const AllRoutes = () =>
    useRoutes([
        {
            path: `/`,
            element: <Root />,
        },
        {
            path: `/es/`,
            children: [
                {
                    path: `/es/`,
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: `/es/apps`,
                    element: <LoadComponent component={Apps} />,
                },
                {
                    path: `/es/workflows`,
                    element: <LoadComponent component={Workflows} />,
                },
                {
                    path: `/es/workflows/cobro-suscripciones`,
                    element: <LoadComponent component={MonthlySubscriptionWorkflows} />,
                },
                {
                    path: `/es/workflows/venta-productos`,
                    element: <LoadComponent component={ProductEcommerceWorkflows} />,
                },
                {
                    path: `/es/workflows/venta-servicios`,
                    element: <LoadComponent component={ServicePaymentWorkflows} />,
                },
                {
                    path: `/es/workflows/intermediacion-transacciones`,
                    element: <LoadComponent component={ProductMarketplaceWorkflows} />,
                },
                {
                    path: `/es/workflows/escrow-servicios`,
                    element: <LoadComponent component={ServiceMarketplaceWorkflows} />,
                },
                {
                    path: `/es/workflows/recuperacion-carrito`,
                    element: <LoadComponent component={CartRecoveryWorkflows} />,
                },
                {
                    path: `/es/privacidad`,
                    element: <LoadComponent component={Privacy} />,
                },
                {
                    path: `/es/cookies`,
                    element: <LoadComponent component={Cookies} />,
                },
                {
                    path: `/es/valid`,
                    element: <LoadComponent component={Valid} />,
                },
                {
                    path: `/es/novalid`,
                    element: <LoadComponent component={Novalid} />,
                },
                {
                    path: `/es/redirect`,
                    element: <LoadComponent component={Redirect} />,
                },
                {
                    path: `/es/mundo_marino_payment`,
                    element: <LoadComponent component={MundoMarinoRedirect} />,
                },
                {
                    path: `/es/mundo_marino_payment/valid`,
                    element: <LoadComponent component={MundoMarinoValid} />,
                },
                {
                    path: `/es/mundo_marino_payment/novalid`,
                    element: <LoadComponent component={MundoMarinoNovalid} />,
                },
            ],
        },
        {
            path: `/en/`,
            children: [
                {
                    path: `/en/`,
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: `/en/apps`,
                    element: <LoadComponent component={Apps} />,
                },
                {
                    path: `/en/workflows`,
                    element: <LoadComponent component={Workflows} />,
                },
                {
                    path: `/en/workflows/subscription-payment`,
                    element: <LoadComponent component={MonthlySubscriptionWorkflows} />,
                },
                {
                    path: `/en/workflows/product-sales`,
                    element: <LoadComponent component={ProductEcommerceWorkflows} />,
                },
                {
                    path: `/en/workflows/service-sales`,
                    element: <LoadComponent component={ServicePaymentWorkflows} />,
                },
                {
                    path: `/en/workflows/transaction-escrow`,
                    element: <LoadComponent component={ProductMarketplaceWorkflows} />,
                },
                {
                    path: `/en/workflows/service-escrow`,
                    element: <LoadComponent component={ServiceMarketplaceWorkflows} />,
                },
                {
                    path: `/en/workflows/cart-recovery`,
                    element: <LoadComponent component={CartRecoveryWorkflows} />,
                },
                {
                    path: `/en/privacy`,
                    element: <LoadComponent component={Privacy} />,
                },
                {
                    path: `/en/cookies`,
                    element: <LoadComponent component={Cookies} />,
                },
                {
                    path: `/en/valid`,
                    element: <LoadComponent component={Valid} />,
                },
                {
                    path: `/en/novalid`,
                    element: <LoadComponent component={Novalid} />,
                },
                {
                    path: `/en/redirect`,
                    element: <LoadComponent component={Redirect} />,
                },
                {
                    path: `/en/mundo_marino_payment/valid`,
                    element: <LoadComponent component={MundoMarinoValid} />,
                },
                {
                    path: `/en/mundo_marino_payment/novalid`,
                    element: <LoadComponent component={MundoMarinoNovalid} />,
                },
            ],
        },
    ]);

export default AllRoutes;
