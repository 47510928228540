import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const Root = () => {
    const { i18n } = useTranslation();
    const url = i18n.language;

    return <Navigate to={`/${url}/`} />;
};

export default Root;
